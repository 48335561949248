import React from "react";
import "./Environment.css";

const Environment = () => {
  return (
    <section className="environment">
      <h1>EXPLORE OUR ENVIRONMENT</h1>
      <div className="env">
        <iframe
          src="https://www.google.com/maps/embed?pb=!4v1708596028285!6m8!1m7!1sCAoSLEFGMVFpcE9JVkNWRno0d2xhUXBYbjh4OXBsNnFMUUtNOEpLbGVYOXppc0xH!2m2!1d22.22832474!2d76.11832414!3f124.00447429759272!4f-3.4315824192130577!5f0.4000000000000002"
          width="80%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
};

export default Environment;
