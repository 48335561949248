let rooms = [
  {
    imageUrl: "room1.jpg",
    name: "PREMIUM ROOM",
  },
  {
    imageUrl: "room2.jpg",
    name: "EMPEROR SUITE",
  },
  {
    imageUrl: "room3.jpg",
    name: "SKY SUITE",
  },

  {
    imageUrl: "room4.jpg",
    name: "DELUXE ROOM",
  },
];

let banquetsLawns = [
  {
    imageUrl: "https://cdn3.shopvii.com/759/969/galaxy.jpg",
    name: "GALAXY",
  },
  {
    imageUrl: "https://cdn3.shopvii.com/759/969/myra.jpg",
    name: "MYRA",
  },
  {
    imageUrl:
      "https://cdn3.shopvii.com/759/969/WhatsApp_Image_2023_05_10_at_3_29_09_PM.jpeg",
    name: "PRISTINE",
  },
  {
    imageUrl: "https://cdn3.shopvii.com/759/969/pearl.jpg",
    name: "PEARL",
  },
  {
    imageUrl: "https://cdn3.shopvii.com/759/969/pyramid.jpg",
    name: "PYRAMID",
  },
  {
    imageUrl: "https://cdn3.shopvii.com/759/969/roof_top.jpg",
    name: "ROOF TOP",
  },
  {
    imageUrl: "https://cdn3.shopvii.com/759/969/lost_heaven.jpg",
    name: "LOST HEAVEN",
  },
];

export { rooms, banquetsLawns };
